import React from "react"
import PropTypes from "prop-types"
import styles from "./WhatWeDo.module.scss"
import graphicCheckmark from "../../../../assets/images/common/graphic-checkmark.svg"
import graphicRectangle from "../../../../assets/images/common/graphic-rectangle.svg"
import { animate } from "../../../../utilities/animate"
import { Holder } from "../../../holder"
import cn from "classnames"
import Wrapper from "../../../wrapper/Wrapper"

const WhatWeDo = () => {
  return (
    <div className={styles.wrapper}>
      <Wrapper>
        <Holder>
          <h6 className={styles.sectionName} {...animate()}>
            What we do
          </h6>
          <h4 className={cn(styles.title, "serif")} {...animate()}>
            Building digital products and startups with daring founders and
            leading corporations.
          </h4>

          <div className={styles.columns}>
            <div className={styles.columnSeparator} {...animate()} />
            <div className={styles.leftCol}>
              <div className={styles.colInner}>
                <img
                  className={styles.image}
                  src={graphicCheckmark}
                  alt="WhatWeDo graphic"
                  {...animate()}
                />
                <h3 className={styles.colTitle} {...animate()}>
                  For founders
                </h3>
                <p className={cn("h5", styles.paragraph)} {...animate()}>
                  Join our team and build your startup from the ground up
                </p>
                <p className={cn("h5", styles.paragraph)} {...animate()}>
                  Use our expertise, approach and network
                </p>
                <p className={cn("h5", styles.paragraph)} {...animate()}>
                  Focus on the product-market-fit, we do the rest
                </p>
              </div>
            </div>
            <div className={styles.rightCol}>
              <div className={styles.colInner}>
                <img
                  className={styles.image}
                  src={graphicRectangle}
                  alt="WhatWeDo graphic"
                  {...animate()}
                />
                <h3 className={styles.colTitle} {...animate()}>
                  For corporates
                </h3>
                <p className={cn("h5", styles.paragraph)} {...animate()}>
                  Partner with us and experience startup speed in corporate
                  environment
                </p>
                <p className={cn("h5", styles.paragraph)} {...animate()}>
                  Leverage our proven greenfield approach
                </p>
                <p className={cn("h5", styles.paragraph)} {...animate()}>
                  Focus on results instead of corporate politics
                </p>
              </div>
            </div>
          </div>
        </Holder>
      </Wrapper>
    </div>
  )
}

WhatWeDo.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

WhatWeDo.defaultProps = {
  className: "",
  children: undefined,
}

export default WhatWeDo
