import React, { useMemo } from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./HowWeBuildCompanies.module.scss"
import { Holder } from "../../../holder"
import { SectionHeading } from "../../../section"
import Wrapper from "../../../wrapper/Wrapper"
import howWeBuildCompaniesSvg from "./assets/how-we-build-companies.svg"
import howWeBuildCompaniesHorizontalXsSvg from "./assets/how-we-build-companies-horizontal-xs.svg"
import howWeBuildCompaniesHorizontalSmSvg from "./assets/how-we-build-companies-horizontal-sm.svg"
import { useBreakpoint } from "../../../../hooks/useBreakpoint"
import { animate } from "../../../../utilities/animate"
import { useWindowSize } from "react-use"

const LineSvg = () => {
  const breakpoint = useBreakpoint()
  const { width } = useWindowSize()
  const dashArrayWidth = useMemo(() => {
    if (breakpoint === "xs") {
      return 360 / width
    }

    return 700 / width
  }, [width, breakpoint])

  return (
    <svg
      width="100%"
      height="100px"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.line}
      preserveAspectRatio="none"
    >
      <line
        x1="0"
        y1="50"
        x2="100"
        y2="50"
        stroke="white"
        strokeWidth={breakpoint === "xs" ? 2 : 1}
        strokeDasharray={`${dashArrayWidth} ${dashArrayWidth}`}
      />
    </svg>
  )
}

const HowWeBuildCompanies = () => {
  const breakpoint = useBreakpoint()

  return (
    <section className={styles.section}>
      {breakpoint !== "xs" && breakpoint !== "sm" && (
        <div className={styles.sectionFullWrap}>
          <Wrapper className={styles.sectionFullWrapInner}>
            <Holder className={styles.sectionFullWrapHolder}>
              <div className={styles.imageWrap}>
                <img
                  src={howWeBuildCompaniesSvg}
                  alt="How we build companies graphic"
                />
              </div>
            </Holder>
          </Wrapper>
        </div>
      )}

      <Wrapper>
        <Holder className={styles.holder}>
          <div className={styles.innerContent}>
            <SectionHeading
              className={styles.sectionHeading}
              order={""}
              animated={true}
            >
              How we build companies
            </SectionHeading>
            <div className={styles.content}>
              <h3 className={styles.title} {...animate()}>
                Build something people want!
              </h3>
              <p className={cn(styles.paragraph, "h5")} {...animate()}>
                We look for problems and inefficiencies in growing markets,
                create solutions, always start small, and test until we have the
                right product!
              </p>
              <p className={cn(styles.paragraph, "h5")} {...animate()}>
                Once we have product-market fit, we start building the company
                around the product.
              </p>
            </div>
          </div>
        </Holder>
      </Wrapper>

      {breakpoint === "xs" && (
        <div className={styles.mobileImageWrap}>
          <LineSvg />
          <img
            src={howWeBuildCompaniesHorizontalXsSvg}
            alt="How we build companies graphic"
          />
        </div>
      )}
      {breakpoint === "sm" && (
        <div className={styles.mobileImageWrap}>
          <LineSvg />
          <img
            src={howWeBuildCompaniesHorizontalSmSvg}
            alt="How we build companies graphic"
          />
        </div>
      )}
    </section>
  )
}

HowWeBuildCompanies.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

HowWeBuildCompanies.defaultProps = {
  className: "",
  children: undefined,
}

export default HowWeBuildCompanies
