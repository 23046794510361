import React from "react"
import { Layout } from "components/layout"
import { Meta } from "components/meta"
import { Hero } from "components/hero"
import ScreenClip from "../components/screen-clip/ScreenClip"
import { graphql } from "gatsby"
import Mission from "../components/sections/about-us/mission/Mission"
import Vision from "../components/sections/about-us/vision/Vision"
import WhatWeDo from "../components/sections/about-us/what-we-do/WhatWeDo"
import HowWeBuildCompanies from "../components/sections/about-us/how-we-build-companies/HowWeBuildCompanies"
import WhyUs from "../components/sections/about-us/why-us/WhyUs"
import Gallery from "../components/sections/about-us/gallery/Gallery"
import WhoWeAre from "../components/sections/about-us/who-we-are/WhoWeAre"

const heroProps = {
  isCenter: true,
  title: "The place where ideas become startups",
}

const AboutUs = ({ location, data }) => {
  return (
    <ScreenClip>
      <Layout location={location}>
        <Meta
          title={"About us"}
          description={
            "The place where ideas become startups. Building the next generation of tech companies with daring founders and leading corporations."
          }
        />
        <Hero {...heroProps} />
        <Gallery
          image1={data.galleryImage1.childImageSharp}
          image2={data.galleryImage2.childImageSharp}
          image3={data.galleryImage3.childImageSharp}
        />
        <WhoWeAre />
        <Mission file={data.missionImage} />
        <Vision file={data.visionImage} />
        <WhatWeDo />
        <HowWeBuildCompanies />
        <WhyUs />
      </Layout>
    </ScreenClip>
  )
}

export const query = graphql`
  query {
    missionImage: file(relativePath: { eq: "about-us/mission.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 860, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    visionImage: file(relativePath: { eq: "about-us/vision.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 860, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryImage1: file(relativePath: { eq: "about-us/gallery-01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 860, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryImage2: file(relativePath: { eq: "about-us/gallery-02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 860, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    galleryImage3: file(relativePath: { eq: "about-us/gallery-03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 860, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

export default AboutUs
