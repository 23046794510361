import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./WhyUs.module.scss"
import { animate } from "../../../../utilities/animate"
import Wrapper from "../../../wrapper/Wrapper"
import { Holder } from "../../../holder"
import { useBreakpoint } from "../../../../hooks/useBreakpoint"

import approachSVG from "./assets/approach.svg"
import checkmarkSVG from "./assets/checkmark.svg"
import growthSVG from "./assets/growth.svg"
import networkSVG from "./assets/network.svg"
import peopleSVG from "./assets/people.svg"
import trophySVG from "./assets/trophy.svg"

const Item = ({ img, imgAlt, children }) => (
  <li className={styles.item} {...animate()}>
    <img className={styles.itemImage} src={img} alt={imgAlt} />
    <p className={cn(styles.itemText, "h5")}>{children}</p>
  </li>
)

const WhyUs = () => {
  const breakpoint = useBreakpoint()
  const TitleTag = breakpoint === "xs" ? "h3" : "h2"

  return (
    <div className={styles.wrapper}>
      <Wrapper>
        <Holder>
          <h6 className={styles.sectionName} {...animate()}>
            Why us
          </h6>
          <div {...animate()}>
            <TitleTag className={styles.title}>
              Reasons to partner with us
            </TitleTag>
          </div>

          <ul className={styles.list}>
            <Item img={growthSVG}>
              Extensive experience in going from 0 to 1
            </Item>
            <Item img={peopleSVG}>Strong engineering and product team</Item>
            <Item img={approachSVG}>
              Efficient and proven startup building approach
            </Item>
            <Item img={trophySVG}>
              Willingness to take risks and do what it takes
            </Item>
            <Item img={networkSVG}>
              Strong network (Talent, investors, corporates)
            </Item>
            <Item img={checkmarkSVG}>Fast execution & no BS attitude</Item>
          </ul>
        </Holder>
      </Wrapper>
    </div>
  )
}

WhyUs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

WhyUs.defaultProps = {
  className: "",
  children: undefined,
}

export default WhyUs
