import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styles from "./Gallery.module.scss"
import Img from "gatsby-image"
import { Controller, Scene } from "react-scrollmagic"
import { Timeline, Tween } from "react-gsap"
import { useBreakpoint } from "../../../../hooks/useBreakpoint"
import Wrapper from "../../../wrapper/Wrapper"
import { Holder } from "../../../holder"
import { animate } from "../../../../utilities/animate"

const imageStyle = {
  position: "absolute",
  maxWidth: "initial",
  maxHeight: "initial",
}

const GalleryDesktop = ({ image1, image2, image3 }) => {
  const isTransformEnabled = window?.devicePixelRatio < 2

  return (
    <div>
      <Controller>
        <Scene
          enabled={isTransformEnabled}
          indicators={false}
          triggerHook={"onEnter"}
          duration={"200%"}
        >
          {(progress) => {
            return (
              <div>
                <Timeline
                  totalProgress={isTransformEnabled ? progress : 0.5}
                  paused
                >
                  <Tween from={{ x: 0 }} to={{ x: -60 }}>
                    <div className={styles.imagesHolder}>
                      <div className={styles.imagesHolderInner}>
                        <div className={styles.image1Wrap}>
                          <Img
                            className={styles.image}
                            fluid={image1?.fluid}
                            alt={"team image 1"}
                            style={imageStyle}
                            fadeIn={true}
                          />
                        </div>

                        <div className={styles.image2Wrap}>
                          <Img
                            className={styles.image}
                            fluid={image2?.fluid}
                            alt={"team image 2"}
                            style={imageStyle}
                            fadeIn={true}
                          />
                        </div>

                        <div className={styles.image3Wrap}>
                          <Img
                            className={styles.image}
                            fluid={image3?.fluid}
                            alt={"team image 3"}
                            style={imageStyle}
                            fadeIn={true}
                          />
                        </div>
                      </div>
                    </div>
                  </Tween>
                </Timeline>
              </div>
            )
          }}
        </Scene>
      </Controller>
    </div>
  )
}

const GalleryMobile = ({ image1, image2, image3 }) => {
  return (
    <Holder>
      <Wrapper>
        <div className={styles.innerContent}>
          <div className={styles.imagesHolderMobile}>
            <div className={styles.image1Wrap}>
              <div className={styles.image1WrapInner}>
                <Img
                  className={styles.image}
                  fluid={image1?.fluid}
                  alt={"team image 1"}
                  style={imageStyle}
                  fadeIn={true}
                />
              </div>
            </div>

            <div className={styles.image2Wrap}>
              <div className={styles.image2WrapInner}>
                <Img
                  className={styles.image}
                  fluid={image2?.fluid}
                  alt={"team image 2"}
                  style={imageStyle}
                  fadeIn={true}
                />
              </div>
            </div>

            <div className={styles.image3Wrap}>
              <div className={styles.image3WrapInner}>
                <Img
                  className={styles.image}
                  fluid={image3?.fluid}
                  alt={"team image 3"}
                  style={imageStyle}
                  fadeIn={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Holder>
  )
}

const Gallery = ({ image1, image2, image3 }) => {
  const breakpoint = useBreakpoint()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return <div className={styles.loadingWrap} {...animate()} />
  }

  return (
    <div {...animate()}>
      {breakpoint === "xs" || breakpoint === "sm" ? (
        <GalleryMobile image1={image1} image2={image2} image3={image3} />
      ) : (
        <GalleryDesktop image1={image1} image2={image2} image3={image3} />
      )}
    </div>
  )
}

Gallery.propTypes = {
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
  image3: PropTypes.object.isRequired,
}

Gallery.defaultProps = {}

export default Gallery
