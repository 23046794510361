import React from "react"
import ContentWithImage from "../../../content-with-image/ContentWithImage"
import { animate } from "../../../../utilities/animate"
import cn from "classnames"
import styles from "./Mission.module.scss"
import { useBreakpoint } from "../../../../hooks/useBreakpoint"

const Mission = ({ file }) => {
  const breakpoint = useBreakpoint()

  return (
    <ContentWithImage
      sectionClassName={styles.section}
      background={"dark"}
      eyebrowText={"Mission"}
      image={{
        alt: "Embrace change",
        fluid: file.childImageSharp.fluid,
      }}
      horizontalAlign={"left"}
      verticalAlign={"top"}
      imageShape={
        breakpoint === "xs" || breakpoint === "sm" ? "square" : "tall"
      }
    >
      <div className={styles.textWrap}>
        <h3 className={styles.title} {...animate()}>
          Building the next generation of tech companies
        </h3>
        <p className={cn(styles.mainText, "h5")} {...animate()}>
          We enable entrepreneurs, intrapreneurs, or anyone with the ambition to
          create something meaningful with all capabilities required to build a
          great tech company.
        </p>
      </div>
    </ContentWithImage>
  )
}

Mission.propTypes = {}

Mission.defaultProps = {}

export default Mission
