import React from "react"
import ContentWithImage from "../../../content-with-image/ContentWithImage"
import { animate } from "../../../../utilities/animate"
import cn from "classnames"
import styles from "./Vision.module.scss"
import { useBreakpoint } from "../../../../hooks/useBreakpoint"

const Vision = ({ file }) => {
  const breakpoint = useBreakpoint()

  return (
    <ContentWithImage
      background={"black"}
      eyebrowText={"Vision"}
      image={{
        alt: "Collaboration",
        fluid: file.childImageSharp.fluid,
      }}
      horizontalAlign={"right"}
      verticalAlign={"bottom"}
      imageShape={
        breakpoint === "xs" || breakpoint === "sm" ? "square" : "tall"
      }
    >
      <div className={styles.textWrap}>
        <h3 className={styles.title} {...animate()}>
          Bringing disruption to the market
        </h3>
        <p className={cn(styles.mainText, "h5")} {...animate()}>
          We want to become one of the leading platforms for
          (tech)-entrepreneurship in Europe and the leading Venture Studio
          operating in southeast Europe.
        </p>
        <p className={cn(styles.mainText, "h5")} {...animate()}>
          The go-to place for founders in globally underrepresented regions that
          want to build tech startups faster and more efficiently!
        </p>
      </div>
    </ContentWithImage>
  )
}

Vision.propTypes = {}

Vision.defaultProps = {}

export default Vision
