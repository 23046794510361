import React from "react"
import cn from "classnames"
import styles from "./WhoWeAre.module.scss"
import { Holder } from "../../../holder"
import { SectionHeading } from "../../../section"
import { animate } from "../../../../utilities/animate"
import Wrapper from "../../../wrapper/Wrapper"

const WhoWeAre = () => {
  return (
    <Wrapper>
      <Holder className={styles.holder}>
        <SectionHeading
          className={styles.sectionHeading}
          order={""}
          animated={true}
        >
          Mindset
        </SectionHeading>
        <div className={styles.content}>
          <h3 className={styles.title} {...animate()}>
            What makes us different
          </h3>
          <div className={styles.paragraphs}>
            <p className={cn(styles.paragraph, "h5")} {...animate()}>
              At M&M we are challenging the way how tech companies are being
              built. We know how hard it is to build a startup but we also know
              that many startups fail for wrong reasons and lack focus on what
              matters the most.
            </p>
            <p className={cn(styles.paragraph, "h5")} {...animate()}>
              We believe in working hard and smart, empowering multidisciplinary
              talent focused on the right thing at the right time. We care about
              our people, don't tolerate bullshit and prefer walk over talk.
            </p>
          </div>
        </div>
      </Holder>
    </Wrapper>
  )
}

WhoWeAre.propTypes = {}

WhoWeAre.defaultProps = {}

export default WhoWeAre
